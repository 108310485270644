import styles from './UserDecline.module.css';
import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import back from '../../icons/back.svg';

import CircularProgress from '@mui/material/CircularProgress';
import ApproveAD from './ApproveAD';
import { renderCenteredAlert, renderCenteredInfo } from '../CenteredAlert';
import { companyInfoState, userInfoState } from '../../state';
import useApiUtils from '../../useApiUtils';
const UserDecline = (props) => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path

  const [delay, setDelay] = React.useState(7);

  const [showApproveAD, setShowApproveAD] = useState(false);
  const [saveButtonState, setSaveButtonState] = useState('normal'); // 'normal', 'saving', 'success', 'failure'
  const defaultSubject = 'Default Subject';
  const defaultContent = 'Default Content';
  const [subject, setSubject] = useState(defaultSubject);
  const [emailContent, setEmailContent] = useState(defaultContent);
  const [companyInfo , setCompanyInfo] = useRecoilState(companyInfoState);
  
  const {
    emailTemplate,

    // Add other functions from the hook if needed
  } = useApiUtils();

  useEffect(() => {
    if (!userInfo || !userInfo.rejection_template) return;
    setSubject(userInfo.rejection_template.subject);
    setEmailContent(userInfo.rejection_template.content);
    setDelay(userInfo.rejection_template.decline_email_delay_days);
  }, [userInfo]);

  const handleSaveChanges = async () => {

    if (companyInfo?.sn_author_invitation && companyInfo?.author_invitation_type === 0 && (!emailContent.includes('{{sn_author_invitation_text}}'))) {
      renderCenteredAlert(
        'You cannot remove the placeholders "{{sn_author_invitation_text}}"'
      );
      setEmailContent(companyInfo.company_rejection_template.company_content);
      return;
    }
    // if (!emailContent.includes('{{Author name}}') || !emailContent.includes('{{Company Name}}')) {
    if (!emailContent.includes('{{Author name}}')) {
      // Show an alert to inform the user
      renderCenteredAlert(
        'You cannot remove the placeholders "{{Author name}}"'
      );
      setEmailContent(userInfo.rejection_template.content);
      return; // Prevent further execution
    }

    if (!emailContent.includes('{{observations}}')) {
      renderCenteredInfo(
        "The observations placeholder has been removed and saved.  To include personalized feedback, click on 'Add Observations Placeholder' and move it to your desired location within the email template by cutting and pasting."
      );
    }

    if (saveButtonState === 'loading') {
      return;
    }
    // Update button state to indicate saving
    setSaveButtonState('loading');

    const requestBody = {
      user_id: userInfo.user_id,
      new_rejection_template: emailContent,
      new_subject: subject,
      decline_email_delay_days: delay,
    };

    try {
      // Call the emailTemplate function
      await emailTemplate(requestBody);

      // If successful, update button state to indicate success
      setSaveButtonState('normal');
      props.setAfterSave(true);
    } catch (error) {
      // If there's an error, update button state to indicate failure
      setSaveButtonState('normal');
    }
  };

  // Define the button text based on the button state
  const getButtonText = () => {
    switch (saveButtonState) {
      case 'saving':
        return 'Saving...';
      case 'success':
        return 'Saved Successfully';
      case 'failure':
        return 'Failed to Save';
      case 'loading':
        return <CircularProgress size={15} color="inherit" />;

      default:
        return props.buttonTextNormal;
    }
  };

  const handleAddObservations = () => {
    if (!emailContent.includes('{{observations}}')) {
      setEmailContent((prev) => prev + ' {{observations}}');
      renderCenteredInfo(
        'The placeholder "{{observations}}" has been added to the end of the content. Please move it to your desired location within the email template by cutting and pasting.'
      );
    } else {
      renderCenteredInfo(
        'The placeholder "{{observations}}" is already included in your template. You can cut and paste it to adjust its location.'
      );
    }
  };

  const handleBack = () => {
    props.pressBackButton();
  };

  return (
    <div className={styles.logosignincontainer}>
      <div className={styles.signcontainer}>
        {showApproveAD && <ApproveAD return={setShowApproveAD} />}
        <div className={styles.body}>
          <div className={styles.titlelogo}>
            <div className={styles.loginparttitle}>
              Edit Personal Rejection Template
            </div>
          </div>

          <div className={styles.loginpartsubtitle1}>
            Edit your standard email rejection mail below:
          </div>
          <div className={styles.labelInputContainer}>
            <label className={styles.label}>Subject</label>
            <input
              className={styles.input}
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className={styles.labelInputContainer}>
            <textarea
              className={`${styles.input} ${styles.textarea}`}
              value={emailContent}
              onChange={(e) => setEmailContent(e.target.value)}
              placeholder={emailContent}
            />
          </div>
          <div
            className={styles.toggleObservationsButton}
            onClick={handleAddObservations}
          >
            Add Observations Placeholder
          </div>
          {/* Number of days to delay sending declined mails: feutere is off now */}
          <div className={styles.label_enable_auto_delay}>
            <div className={styles.label_enable_auto}>
              The decline email will be sent after 2 days.
            </div>
          </div>
        </div>
        <div className={styles.fotter}>
          {props.showBackButton && (
            <div className={styles.pagenumber}>
              <div className={styles.pagenumberfirst}>4</div>/
              {props.superadmin ? '6' : '4'}
            </div>
          )}
          <div className={styles.buttoncontainer}>
            {props.showBackButton && (
              <div className={styles.buttoncontainerback}>
                <img src={back} className={styles.back} />
                <div
                  className={styles.buttonBack}
                  onClick={handleBack}
                  data-testid="back"
                >
                  <div className={styles.textbuttonBack}>Back</div>
                </div>
              </div>
            )}
            <div
              className={`${styles.button} ${saveButtonState === 'success'
                  ? styles.successButton
                  : saveButtonState === 'failure'
                    ? styles.failureButton
                    : ''
                }`}
              onClick={handleSaveChanges}
            >
              <div className={styles.textbutton}>{getButtonText()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDecline;
