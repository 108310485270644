import styles from './Email.module.css';
import resize from '../../icons/New Message/Icon/Open in/Initial.svg';
import close from '../../icons/New Message/Icon/Close/close/White.svg';
import expend from '../../icons/Button/Arrow.svg';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import {
  FaTimes,
  FaRegClosedCaptioning,
  FaRegTrashAlt,
  FaRegWindowRestore,
} from 'react-icons/fa';
import { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import useApiUtils from '../../useApiUtils';
import { ingestDetailsState } from '../../state';
import CircularProgress from '@mui/material/CircularProgress';

const Email = (props) => {
  const { downloadEmail } = useApiUtils();

  const [isExpanded, setIsExpanded] = useState(false);
  const [ingestionDetails, setIngestionDetails] =
    useRecoilState(ingestDetailsState);

  const OurRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (OurRef.current && !OurRef.current.contains(event.target)) {
        props.return(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [OurRef]);

  const closeb = () => {
    props.return(false);
  };

  const handledwon = (file_id, book_id) => {
    downloadEmail(file_id);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.containerBox}
        ref={OurRef}
        style={{
          height: isExpanded ? '90vh' : '60vh',
          width: isExpanded ? '60vw' : '30vw',
        }}
      >
        <div className={styles.header}>
          <div className={styles.headerContent}>Email submission</div>
          <div className={styles.headerButons}>
            <div
              className={styles.headerButonsResize}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {/* <img src={resize} alt="resize" className={styles.icon} /> */}
              <FaRegWindowRestore color="white" size="1.3em" />
            </div>
            <div className={styles.headerButonsClose} onClick={closeb}>
              <FaTimes color="white" size="1.3em" />
            </div>
          </div>
        </div>
        {!ingestionDetails && (
          <div className={styles.spinnerContainer}>
            <CircularProgress
              size={60}
              sx={{ color: '#1CEAA0' }}
              thickness={5}
            />
          </div>
        )}
        <>
          <div className={styles.bodyContentBodyContentfrom}>
            <div className={styles.bodyContentBodyContentSubjecttitel}>
              <span className={styles.From}>From: </span>
              {ingestionDetails?.ingest_source}
            </div>
          </div>
          <div className={styles.bodyContentBodyContentfrom}>
            <div className={styles.bodyContentBodyContentSubjecttitel}>
              <span className={styles.From}>Subject: </span>
              {ingestionDetails?.subject}{' '}
            </div>
          </div>
          <div
            className={styles.bodyContentBodyContentBody}
            style={{ height: isExpanded ? '66vh' : '39vh' }}
          >
            <div className={styles.bodytext}>
              <div className={styles.bodytexttitel}>
                {ingestionDetails?.message_body}
              </div>
            </div>
            <div className={styles.bodybuttons}>
              {ingestionDetails?.attachment_list.map((attachment, index) => (
                <div
                  className={styles.bodybutton}
                  onClick={() =>
                    handledwon(attachment.file_id, ingestionDetails.book_id)
                  }
                >
                  <div key={index} className={styles.bodybutton1}>
                    {attachment.filename}
                  </div>
                  {/* <img src={expend} alt="expend" /> */}
                  <FaCloudDownloadAlt color="white" size="1.3em" />
                </div>
              ))}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Email;
