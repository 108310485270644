import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

// Convert similarity score (0.1-0.5) to a 1-5 star rating
export function convertToStarRating(similarity) {
  const clampedSimilarity = Math.max(0.1, Math.min(0.5, similarity));
  return 1 + ((clampedSimilarity - 0.1) / (0.5 - 0.1)) * 4;
}

// Component to display all genres' taste scores with stars
export function AllTasteScores({ tasteMatch, bookTasteInfo }) {
  if (tasteMatch == null || tasteMatch.length === 0) {
    return '';
  }

  return (
    <div>
      {tasteMatch.map(([genre, score], index) => {
        // Ensure the star rating is within bounds
        const starRating = Math.min(5, Math.max(1, convertToStarRating(score)));
        const fullStars = Math.floor(starRating);
        const halfStar = starRating % 1 >= 0.5;
        const emptyStars = Math.max(0, 5 - fullStars - (halfStar ? 1 : 0));

        let response = '';

        // Find the corresponding response
        if (bookTasteInfo && bookTasteInfo.length > 0) {
          const bookTasteItem = bookTasteInfo.find(
            (item) => item.genre === genre
          );
          response = bookTasteItem ? bookTasteItem.response : '';
        }

        return (
          <div key={`genre-${index}`} style={{ marginBottom: '2px' }}>
            <div
              style={{
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: index === 0 ? 'bold' : 'normal', // Bolder for the first genre
                display: 'flex',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              <span style={{ marginRight: '2px' }}>{genre}</span>
              {Array.from({ length: fullStars }).map((_, i) => (
                <FaStar key={`full-${index}-${i}`} color="#1CEAA0" size="13" />
              ))}
              {/* Half star */}
              {halfStar && (
                <FaStarHalfAlt
                  key={`half-${index}`}
                  color="#1CEAA0"
                  size="13"
                />
              )}
              {/* Empty stars */}
              {Array.from({ length: emptyStars }).map((_, i) => (
                <FaRegStar
                  key={`empty-${index}-${i}`}
                  color="#1CEAA0"
                  size="13"
                />
              ))}
            </div>

            <div>{response}</div>
          </div>
        );
      })}
    </div>
  );
}
