import styles from './EmailIntegration.module.css';
import React, { useState } from 'react';
import useApiUtils from '../../useApiUtils';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import xxx from '../../icons/xxx.svg';
import trash from '../../icons/vuesax/outline/trash.svg';
import {
  companyInfoState,
  userInfoState,
  numberOfUploadsState,
  uploadMethodOptionState,
  manuscriptsUploadsState,
  uploadMethodcheckedState,
  uploadMethodProviderState,
} from '../../state';
import Checkbox from '@mui/material/Checkbox';

import gmail from '../../icons/gmail.svg';
import gmailHover from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_focus_web.png';
import gmailPreesed from '../../icons/google_signin_buttons 2/web/1x/btn_google_signin_light_pressed_web.png';
import outlook from '../../icons/outlook.svg';
import { useScrollTrigger } from '@mui/material';
import { renderCenteredAlert } from '../CenteredAlert';

const EmailIntegration = (props) => {
  const [numberOfUploads, setNumberOfUploads] =
    useRecoilState(numberOfUploadsState);
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [selectedProvider, setSelectedProvider] = React.useState('');
  const [manuscriptsUploads, setManuscriptsUploads] = useRecoilState(
    manuscriptsUploadsState
  );
  const [options, setOptions] = useRecoilState(uploadMethodOptionState);
  const [isProviderSelected, setIsProviderSelected] = React.useState(false);

  //i want to detect click outside of the deletebox
  const deleteboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        deleteboxRef.current &&
        !deleteboxRef.current.contains(event.target)
      ) {
        props.setIntegration_window(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deleteboxRef]);

  const REACT_APP_BUILD_ENV =
    process.env.REACT_APP_BUILD_ENV || 'REACT_APP_BUILD_ENV-build_env';

  const REACT_APP_CALLBACK_BASE_URL = process.env.REACT_APP_CALLBACK_BASE_URL || 'REACT_APP_CALLBACK_BASE_URL';

  let GMAIL_REDIRECT_URI;
  let OUTLOOK_REDIRECT_URI;
  if (REACT_APP_BUILD_ENV === 'live') {
    // GMAIL_REDIRECT_URI = 'https://publishers.storywise.ai/user-profile';
    GMAIL_REDIRECT_URI = `${REACT_APP_CALLBACK_BASE_URL}/user-profile`;
    // OUTLOOK_REDIRECT_URI = 'https://publishers.storywise.ai/user-profile';
    OUTLOOK_REDIRECT_URI = `${REACT_APP_CALLBACK_BASE_URL}/user-profile`;
  } else if (REACT_APP_BUILD_ENV === 'dev') {
    // Use REACT_APP_BASE_URL to build URL
    GMAIL_REDIRECT_URI = `${REACT_APP_CALLBACK_BASE_URL}/user-profile`;
    // GMAIL_REDIRECT_URI = 'https://dev-publishers.storywise.ai/user-profile';
    OUTLOOK_REDIRECT_URI = `${REACT_APP_CALLBACK_BASE_URL}/user-profile`;
    // OUTLOOK_REDIRECT_URI = 'https://dev-publishers.storywise.ai/user-profile';
  } else if (REACT_APP_BUILD_ENV === 'staging') {
    // GMAIL_REDIRECT_URI = 'https://staging-publishers.storywise.ai/user-profile';
    GMAIL_REDIRECT_URI = `${REACT_APP_CALLBACK_BASE_URL}/user-profile`;
    // OUTLOOK_REDIRECT_URI = 'https://staging-publishers.storywise.ai/user-profile';
    OUTLOOK_REDIRECT_URI = `${REACT_APP_CALLBACK_BASE_URL}/user-profile`;
  }

  const handleEmailoutlookProviderClick = async () => {
    if (userInfo.role === 'lvl21') {
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }
    setSelectedProvider('outlook');

    // Store the current state before redirection
    const currentState = {
      manuscriptsUploads: manuscriptsUploads,
      options: options,
      selectedProvider: 'outlook',
      location_is_upload: true,
      numberOfUploads: numberOfUploads,
      location_is_upload: true,
    };

    // Store the current state in local storage
    localStorage.setItem('currentState', JSON.stringify(currentState));
    let loginUrl;

    const OUTLOOK_CLIENT_ID = '0301269c-a549-4d76-9a93-86c458e13e68';
    const loginEndpoint =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const params = new URLSearchParams({
      client_id: OUTLOOK_CLIENT_ID,
      response_type: 'code',
      redirect_uri: OUTLOOK_REDIRECT_URI,
      // scope: "openid Mail.ReadWrite",
      scope: 'https://graph.microsoft.com/.default',
    });

    loginUrl = `${loginEndpoint}?${params.toString()}`;
    if (loginUrl) {
      window.location.href = loginUrl;
    }
  };

  const handleEmailGmailProviderClick = async () => {
    if (userInfo.role === 'lvl21') {
      renderCenteredAlert('You are not authorized to perform this action');
      return;
    }
    setSelectedProvider('gmail');

    // Store the current state before redirection
    const currentState = {
      manuscriptsUploads: manuscriptsUploads,
      options: options,
      selectedProvider: 'gmail',
      location_is_upload: true,
      numberOfUploads: numberOfUploads,
      location_is_upload: true,
    };
    // Store the current state in local storage
    localStorage.setItem('currentState', JSON.stringify(currentState));
    let loginUrl;

    const GMAIL_CLIENT_ID =
      '88252056465-689ijnomq4moe5ieb1sfai8j6klk5s7s.apps.googleusercontent.com';
    const loginEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({
      client_id: GMAIL_CLIENT_ID,
      response_type: 'code',
      redirect_uri: GMAIL_REDIRECT_URI,
      scope: 'https://www.googleapis.com/auth/gmail.readonly',
      access_type: 'offline',
      prompt: 'consent',
    });

    loginUrl = `${loginEndpoint}?${params.toString()}`;

    if (loginUrl) {
      window.location.href = loginUrl;
    }
  };

  const handleCancel = () => {
    props.setIntegration_window(false);
  };

  const handleIntegrate = () => {
    if (checked === false) {
      // setErrorMessage('Please accept the privacy policy and terms of conditions');
      renderCenteredAlert(
        'Please accept the privacy policy and terms of conditions'
      );
      return;
    }
    // if (numberOfUploads === 0) {
    //     setErrorMessage("Please select the number of days")
    //     return;
    // }
    if (numberOfUploads > 90) {
      setErrorMessage('The number of days cannot be more than 90');
      renderCenteredAlert('The number of days cannot be more than 90');
      return;
    }
    if (numberOfUploads < 0) {
      setErrorMessage('The number of days cannot be less than 0');
      renderCenteredAlert('The number of days cannot be less than 0');
      return;
    }
    if (selectedProvider === 'gmail') {
      handleEmailGmailProviderClick();
    } else {
      handleEmailoutlookProviderClick();
    }
  };

  const handleChange = (event) => {
    setChecked(!checked);
  };

  const handleLinkClicktoPrivacy = (event) => {
    event.preventDefault(); // Prevent the default link behavior

    window.open('/privacy-policy', '_blank');
    setChecked(true);
  };

  const handleLinkClickToTerm = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    window.open('https://www.storywise.ai/Terms', '_blank');
  };

  const handleGmail = () => {
    setSelectedProvider('gmail');
  };
  const handleoutlook = () => {
    setSelectedProvider('outlook');
  };

  const handleContinue = () => {
    if (selectedProvider === '') {
      renderCenteredAlert('Please select an email provider');
      return;
    }
    setIsProviderSelected(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.deletebox} ref={deleteboxRef}>
        <div className={styles.boxsplit}>
          <div className={styles.title}>
            <div className={styles.titleText}>Integrates with your email</div>
            <div className={styles.buttonOut} onClick={handleCancel}>
              <img className={styles.xbutton} src={xxx} />
            </div>
          </div>
          {!isProviderSelected ? (
            <div className={styles.deleteboxContent}>
              <div className={styles.editboxContentTextNimage}>
                <div className={styles.editboxContent}>
                  <div className={styles.titl}>Choose your email provider</div>

                  <div
                    className={`${styles.imageUploadPart1} ${
                      selectedProvider === 'gmail'
                        ? styles.imageContainerSelected
                        : styles.imageContainerUnselected
                    }`}
                    onClick={handleGmail}
                    id="gmail"
                    key="gmail"
                  >
                    <div className={styles.imageContainer1}>
                      {/* <div className={`${styles.imageContainer1} ${selectedProvider === 'gmail' ? styles.imageContainerSelected : styles.imageContainerUnselected}`} onClick={handleGmail}> */}
                      <img
                        className={styles.imageContainerImage1}
                        src={gmail}
                        alt="Gmail"
                      />
                      <div className={styles.emailProviderName}>Gmail</div>
                    </div>
                  </div>
                  <div
                    className={`${styles.imageUploadPart1} ${
                      selectedProvider === 'outlook'
                        ? styles.imageContainerSelected
                        : styles.imageContainerUnselected
                    }`}
                    onClick={handleoutlook}
                    id="outlook"
                    key="outlook"
                  >
                    <div className={styles.imageContainer1}>
                      {/* <div className={`${styles.imageContainer1} ${selectedProvider === 'outlook' ? styles.imageContainerSelected : styles.imageContainerUnselected}`} onClick={handleoutlook}> */}
                      <img
                        className={styles.imageContainerImage2}
                        src={outlook}
                        alt="Outlook"
                      />
                      <div className={styles.emailProviderName}>Outlook</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.buttons}>
                <div className={styles.cancelButtton} onClick={handleCancel}>
                  <div className={styles.cancelButttonText}>Cancel</div>
                </div>
                <div className={styles.deleteButtton} onClick={handleContinue}>
                  {/* <img className={styles.deleteButttonImage} src={trash} alt="delete" /> */}
                  <div className={styles.deleteButttonText}>Continue</div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.deleteboxContent}>
              <div className={styles.editboxContentTextNimage}>
                <div className={styles.imageUploadPart}>
                  {selectedProvider === 'gmail' && (
                    <>
                      <img
                        className={styles.book_image}
                        src={gmail}
                        alt="book"
                      />
                      <div className={styles.book_name}>
                        We are about to integrated your Gmail account...
                      </div>
                    </>
                  )}
                  {selectedProvider === 'outlook' && (
                    <>
                      <img
                        className={styles.outlook_image}
                        src={outlook}
                        alt="book"
                      />
                      <div className={styles.book_name}>
                        We are about to integrated your Outlook account...
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.editboxContentTextNimage2}>
                  <div className={styles['word-count-container']}>
                    <div className={styles.titles}>
                      How far back should we go to start searching your inbox
                      for manuscripts? (max 90 days)
                    </div>
                    <input
                      type="number"
                      value={numberOfUploads}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        if (newValue >= 0 && newValue <= 90) {
                          setNumberOfUploads(newValue);
                        } else {
                          // Optional: Reset to nearest boundary or set to a default value
                          setNumberOfUploads(newValue < 0 ? 0 : 90);
                        }
                      }}
                      placeholder={numberOfUploads}
                      className={styles.input}
                      max={90}
                      min={0}
                    />

                    <div className={styles.titles}>days</div>
                  </div>
                  {selectedProvider === 'gmail' && (
                    <div className={styles.googleWarning}>
                      <div className={styles.warningTitle}>
                        Storywise's use and transfer of information received
                        from Google APIs to any other app will adhere to{' '}
                        <a
                          href="https://developers.google.com/terms/api-services-user-data-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.privacyLink}
                        >
                          Google API Services User Data Policy
                        </a>{' '}
                        , including the Limited Use requirements.
                      </div>
                    </div>
                  )}

                  <div className={styles.checkContainer}>
                    <div className={styles.checkboxContainer}>
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }}
                      />
                      I have read and agree to the
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleLinkClicktoPrivacy}
                        className={styles.privacyLink}
                      >
                        Privacy Policy
                      </a>
                      and
                      <a
                        href="https://www.storywise.ai/Terms"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleLinkClickToTerm}
                        className={styles.privacyLink}
                      >
                        Terms of conditions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttons}>
                <div className={styles.cancelButtton} onClick={handleCancel}>
                  <div className={styles.cancelButttonText}>Cancel</div>
                </div>
                <div className={styles.deleteButtton} onClick={handleIntegrate}>
                  <div className={styles.deleteButttonText}>Integrate</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailIntegration;
