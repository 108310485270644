import styles from './RejectManuscript.module.css';
import {
  scoreDataAtom,
  companyInfoState,
  clickedManuscriptAtom,
  userInfoState,
} from '../../../state';
import { useRecoilState } from 'recoil';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { useEffect, useRef, useState } from 'react';
import useApiUtils from '../../../useApiUtils';
import CircularProgress from '@mui/material/CircularProgress';
import { renderCenteredAlert } from '../../CenteredAlert';

const RejectManuscript = (props) => {
  const [clickedManuscript, setClickedManuscript] = useRecoilState(
    clickedManuscriptAtom
  );
  const [userInfo, setUserInfo] = useRecoilState(userInfoState); // Update the path
  const [companyInfo, setCompanyInfo] = useRecoilState(companyInfoState);
  const [authorEmailInputt, setAuthorEnailInputt] = useState(clickedManuscript.author_email);

  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [scoreData, setScoreData] = useRecoilState(scoreDataAtom);
  const [iscontentChanged, setIscontentChanged] = useState(false);

  const {
    getManuscriptsInfo,
    initialgetBookChapter,
    get_book_scores,
    getbookgenrekeywords,
    sendRejection,
    sendApprove,
    editusersettings,
    EditmanuscriptApi,
    // Add other functions from the hook if needed
  } = useApiUtils();

  const handelbutton = () => {
    props.return(false);
  };

  const Editmanuscript = async (authorEmailInput) => {
    let genresToreturn = [];

    if (
      clickedManuscript.genres === null ||
      clickedManuscript.genres === 'none'
    ) {
      genresToreturn = [];
    } else {
      genresToreturn = clickedManuscript.genres;
    }

    const requestPayload = {
      user_id: userInfo.user_id,
      edit_manuscript_info: [
        {
          book_id: clickedManuscript.book_id,
          manuscript_name: clickedManuscript.manuscript_name,
          author_name: clickedManuscript.author_name,
          genres: genresToreturn,
          author_email:
            authorEmailInput !== ''
              ? authorEmailInput
              : clickedManuscript.author_email,
        },
      ],
    };
    await EditmanuscriptApi(requestPayload);
  };

  const handelbuttonReject = async () => {
    // if the email is changed and the email string is not empty
    if (isEmailChanged) {
      // validate the email
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(authorEmailInputt) && checked) {
        renderCenteredAlert('Invalid email address');
        return;
      }
      await Editmanuscript(authorEmailInputt);
    }
    if (companyInfo?.sn_author_invitation && companyInfo?.author_invitation_type === 0 &&  (!rejectText.includes('{{sn_author_invitation_text}}'))) {
      renderCenteredAlert(
        'You cannot remove the placeholders "{{sn_author_invitation_text}}"'
      );
      setClickedManuscript(clickedManuscript);
      return;
    }

    props.return(false);
    sendRejection(clickedManuscript.book_id, iscontentChanged, rejectText);
    // props.setUserRejected(true);
  };

  //i want to detect click outside of the deletebox
  const rejectboxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        rejectboxRef.current &&
        !rejectboxRef.current.contains(event.target)
      ) {
        props.return(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [rejectboxRef]);

  const [rejectText, setRejectText] = useState('');

  useEffect(() => {
    const fullContent = userInfo.rejection_template.content;
    const authorNamePlaceholder = '{{Author name}}';
    const companyNamePlaceholder = '{{Company Name}}';

    const observationsPlaceholder = '{{observations}}';

    let contentWithCompanyName = fullContent.replace(
      companyNamePlaceholder,
      companyInfo.company_name || 'Unknown Company'
    );

    let contentWithAuthorName = contentWithCompanyName.replace(
      authorNamePlaceholder,
      clickedManuscript.author_name || 'Unknown recipient'
    );

    let observationsText = ''; // Default to empty string if no specific feedback is available

    // Only try to replace observations placeholder if scoreData.lowest_score is defined and has elements
    if (scoreData.lowest_score && scoreData.lowest_score.length > 0) {
      observationsText =
        "We'd like to offer you the following feedback provided to us by our Acquisitions team. We hope it provides you with some direction and would encourage you to resubmit again in a few months.\n\n• " +
        scoreData.lowest_score[0];
      if (scoreData.lowest_score.length > 1) {
        observationsText += '\n• ' + scoreData.lowest_score[1]; // Add the second observation if available
      }
    }

    let contentWithObservations = contentWithAuthorName.replace(
      observationsPlaceholder,
      observationsText
    );

    setRejectText(contentWithObservations);
    if (userInfo.user_config?.send_decline_email) {
      setChecked(true);
    }

    // }
  }, [userInfo, clickedManuscript]);
  //create mock data
  const currentDate = new Date();
  const currentTime = currentDate.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  const formattedDateTime =
    currentDate.toISOString().split('T')[0] + ' ' + currentTime;

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#1CEAA0',
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#a1e0c9',
    },
  }));

  const [loadingChangeAutoEmail, setLoadingChangeAutoEmail] = useState(false);

  const [checked, setChecked] = useState(false);
  const handleChange = async (event) => {
    if (loadingChangeAutoEmail) {
      return;
    }
    setLoadingChangeAutoEmail(true);
    setChecked(event.target.checked);
    const requestPayload = {
      user_id: userInfo.user_id,
      send_decline_email: event.target.checked,
    };
    //send upadte to the server
    await editusersettings(requestPayload);
    //update the local state
    setUserInfo({
      ...userInfo,
      user_config: {
        ...userInfo.user_config,
        send_decline_email: event.target.checked,
      },
    });
    setLoadingChangeAutoEmail(false);
  };

  const delayDays =
  userInfo?.rejection_template.decline_email_delay_days || 0;

  // Calculate the future date
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + delayDays);

  // Format the future date as an ISO string and extract the date part
  const formattedFutureDate = futureDate.toISOString().split('T')[0];

  // const { img, title, outher, emailText } = props;
  return (
    <div className={styles.backdrop}>
      <div className={styles.container} ref={rejectboxRef}>
        <>
          <div className={styles.TopSection}>
            <div className={styles.titleTEXT}>Decline Manuscript?</div>
            <div className={styles.buttonOut}>
              <button className={styles.xbutton} onClick={handelbutton}>
                X
              </button>
            </div>
          </div>
          <div className={styles.MiddleSectionall}>
            <div className={styles.Middle}>
              <div className={styles.MiddleSection}>
                <div className={styles.ImageContainer}>
                  <img
                    className={styles.Image}
                    src={clickedManuscript.image}
                    alt=""
                  />
                </div>
                <div className={styles.title}>
                  {clickedManuscript.manuscript_name}
                </div>
                <div className={styles.outherName}>
                  By{' '}
                  <div className={styles.outher}>
                    {clickedManuscript.author_name}
                  </div>
                </div>
              </div>
              <div className={styles.ConfirmationTitle}>
                Are You Sure You Want to Decline This Manuscript?
              </div>
              {!checked && (
                <div className={styles.ConfirmationSubtitle}>
                  No decline email will be sent
                </div>
              )}
              {checked && (
                <div className={styles.ConfirmationSubtitle}>
                  If you decline this manuscript, the following email will be
                  sent to the author on {formattedFutureDate}
                </div>
              )}
              <div className={styles.enable_auto}>
                {!loadingChangeAutoEmail && (
                  <PinkSwitch
                    checked={checked}
                    size="small"
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                )}
                {
                  loadingChangeAutoEmail && (
                    //    <button className={styles.buttonDownload}>
                    <CircularProgress style={{ color: '#1CEAA0' }} size={20} />
                  )
                  //    </button>
                }
                <div className={styles.ConfirmationSubtitle}>
                  Send email when Declining{' '}
                </div>
              </div>
              {checked && (
                <>
                  <div className={styles.authoremail}>
                    <div className={styles.toSubtitle}>Email:</div>
                    <div className={styles.EmailText}>
                      <input
                        className={styles.EmailBodymail}
                        defaultValue={authorEmailInputt}
                        value={authorEmailInputt}
                        // onchange set the author email and setIsEmailChanged  to true
                        onChange={(e) => {
                          setAuthorEnailInputt(e.target.value);
                          setIsEmailChanged(true);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.EmailText}>
                    <textarea
                      className={styles.EditableRejectText}
                      defaultValue={rejectText}
                      onChange={(e) => {
                        setIscontentChanged(true);

                        setRejectText(e.target.value);
                      }}
                      // any additional props you need
                    />
                  </div>
                </>
              )}
            </div>

            <div className={styles.ButtonSection}>
              <div
                className={styles.YesButtonContainer}
                onClick={handelbuttonReject}
              >
                <div className={styles.yesButton}>Yes, Decline</div>
              </div>
              <div className={styles.NoButtonContainer} onClick={handelbutton}>
                <div className={styles.noButton}>No, Don’t Decline</div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
export default RejectManuscript;
